import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 28 28"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M19.5 15.6H18c-.5 0-.8.4-.8.8V18c0 .5.4.8.8.8h1.6c.5 0 .8-.4.8-.8v-1.6c0-.4-.4-.8-.9-.8M15.6 8.5V6.9c0-.5-.4-.8-.8-.8h-1.6c-.5 0-.8.4-.8.8v1.6c0 .5.4.8.8.8h1.6c.5.1.8-.3.8-.8M7.6 16.4V18c0 .5.4.8.8.8H10c.5 0 .8-.4.8-.8v-1.6c0-.5-.4-.8-.8-.8H8.5c-.5 0-.9.4-.9.8" }, null, -1),
    _createElementVNode("path", { d: "M14 0C6.3 0 0 6.3 0 14s6.3 14 14 14 14-6.3 14-14S21.7 0 14 0m7.9 18c0 1.3-1 2.3-2.3 2.3H18c-1.3 0-2.3-1-2.3-2.3v-1.6c0-1.3 1-2.3 2.3-2.3 0-.5-.4-.8-.8-.8h-6.3c-.5 0-.8.4-.8.8 1.3 0 2.3 1 2.3 2.3V18c0 1.3-1 2.3-2.3 2.3H8.5c-1.3 0-2.3-1-2.3-2.3v-1.6c0-1.3 1-2.3 2.3-2.3 0-1.3 1-2.3 2.3-2.3h2.4v-.9c-1.3 0-2.3-1-2.3-2.3V6.9c0-1.3 1-2.3 2.3-2.3h1.6c1.3 0 2.3 1 2.3 2.3v1.6c0 1.3-1 2.3-2.3 2.3v.9h2.4c1.3 0 2.3 1 2.3 2.3 1.3 0 2.3 1 2.3 2.3V18z" }, null, -1)
  ])))
}
export default { render: render }