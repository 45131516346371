import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Layer 2",
  viewBox: "0 0 25.87 25.87"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M17.07 12.93a4.127 4.127 0 0 1-2.56 3.82c-.76.31-1.59.4-2.39.24s-1.54-.55-2.12-1.13-.97-1.32-1.13-2.12-.08-1.64.24-2.39a4.13 4.13 0 0 1 3.82-2.56c1.1 0 2.15.44 2.92 1.22.78.78 1.21 1.83 1.22 2.92m8.8-5.69v11.38a7.236 7.236 0 0 1-7.24 7.24H7.24A7.236 7.236 0 0 1 0 18.62V7.24A7.236 7.236 0 0 1 7.24 0h11.38a7.236 7.236 0 0 1 7.24 7.24Zm-6.73 5.69c0-1.23-.36-2.43-1.05-3.45a6.204 6.204 0 0 0-6.38-2.64c-1.2.24-2.31.83-3.18 1.7s-1.46 1.97-1.7 3.18a6.2 6.2 0 0 0 .35 3.59c.47 1.13 1.27 2.1 2.29 2.79 1.02.68 2.22 1.05 3.45 1.05 1.65 0 3.23-.65 4.39-1.82a6.22 6.22 0 0 0 1.82-4.39Zm2.07-6.73c0-.31-.09-.61-.26-.86-.17-.26-.41-.45-.7-.57-.28-.12-.6-.15-.9-.09a1.56 1.56 0 0 0-1.22 1.21c-.06.3-.03.61.09.9.12.28.32.53.57.7s.55.26.86.26c.41 0 .81-.16 1.1-.45s.45-.69.45-1.1Z",
      "data-name": "Layer 1"
    }, null, -1)
  ])))
}
export default { render: render }