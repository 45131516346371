import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Layer 2",
  viewBox: "0 0 28 28"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      "data-name": "Layer 1",
      style: {"fill":"none","mask":"url(#mask)"}
    }, [
      _createElementVNode("path", {
        d: "M0 14.08C0 21.04 5.05 26.83 11.67 28V17.89h-3.5V14h3.5v-3.11c0-3.5 2.25-5.44 5.44-5.44 1.01 0 2.1.16 3.11.31v3.58h-1.79c-1.71 0-2.1.86-2.1 1.94V14h3.73l-.62 3.89h-3.11V28C22.94 26.83 28 21.04 28 14.08 28 6.34 21.7 0 14 0S0 6.34 0 14.08",
        style: {"fill-rule":"evenodd"}
      })
    ], -1)
  ])))
}
export default { render: render }