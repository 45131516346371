import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Layer 2",
  viewBox: "0 0 28 28"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M14 0C6.27 0 0 6.27 0 14s6.27 14 14 14 14-6.27 14-14S21.73 0 14 0m6.42 11.3c-.74 4.25-4.88 7.86-6.13 8.68s-2.38-.33-2.79-1.2c-.47-.99-1.89-6.38-2.26-6.82-.37-.45-1.48.45-1.48.45l-.54-.72s2.26-2.75 3.97-3.09c1.82-.36 1.82 2.85 2.26 4.63.42 1.73.71 2.71 1.08 2.71s1.08-.96 1.85-2.44c.78-1.48-.03-2.78-1.55-1.85.61-3.7 6.33-4.6 5.59-.34Z",
      "data-name": "Layer 1"
    }, null, -1)
  ])))
}
export default { render: render }