import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Layer 2",
  viewBox: "0 0 28 21.56"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M14 0C.24 0 0 1.22 0 10.78s.24 10.78 14 10.78 14-1.22 14-10.78S27.76 0 14 0m4.49 11.25-6.29 2.93c-.55.25-1-.03-1-.64V8.01c0-.61.45-.89 1-.64l6.29 2.93c.55.26.55.68 0 .94Z",
      "data-name": "Layer 1"
    }, null, -1)
  ])))
}
export default { render: render }