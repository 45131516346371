import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 28 28"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M14 0C6.3 0 0 6.3 0 14s6.3 14 14 14 14-6.3 14-14S21.7 0 14 0m-3.6 19.8c-1 0-1.8-.8-1.8-1.8s.8-1.9 1.8-1.9 1.8.8 1.8 1.9-.8 1.8-1.8 1.8m4.5 0c0-3.5-2.9-6.3-6.3-6.3v-2.6c4.9 0 8.9 4 8.9 8.9zm4.5 0C19.4 13.9 14.5 9 8.6 9V6.3c7.4 0 13.5 6.1 13.5 13.5z" }, null, -1)
  ])))
}
export default { render: render }